import {Avatar, Button} from "antd";
import Title from "antd/es/typography/Title";
import {Link} from "react-router-dom";
import {useMemo} from "react";
import CompanyServices from "../API/companyServices";


export  const FilterDataCompany = (data, query) => {
    return useMemo(() => {
        if (query === 'b') {
            return [...data].reverse();
        } else {
            return data;
        }
    }, [query, data]);
};



export const CompanyStructure = (users, query) => {
    const sortedData = FilterDataCompany(users, query);
    return useMemo(() => {
        return  sortedData.map((item, key) => {
            return {
                key: key + 1,
                name: (
                    <>
                        <Avatar.Group>
                            <Avatar
                                className="shape-avatar"
                                shape="square"
                                size={40}
                                src={window.atob(item.imageBytes)}
                            />
                            <div className="avatar-info">
                                <Title level={5}>{item.companyName}</Title>

                            </div>
                        </Avatar.Group>{" "}
                    </>
                ),
                email: (
                    <>
                        <div className="author-info">
                            <p>{item.email}</p>
                        </div>
                    </>
                ),

                mobile: (
                    <>
                        <div className="author-info">
                            <p>{item.mobile}</p>
                        </div>
                    </>
                ),
                location: (
                    <>
                        <div className="ant-employed">
                            <span>{item.location ? item.location : item.address}</span>
                            <Link to={`/superAdmin/company-profile?id=${item.accountId}&role=${item.role}`}>
                                view
                            </Link>
                        </div>
                    </>
                ),
                remove: (
                    <>
                        <div className="author-info">
                            <Button
                                type="danger"
                                htmlType="button"
                                onClick={async () => {

                                    const response = await CompanyServices.deleteCompany(item.accountId)
                                    console.log(response);
                                }}
                            >
                                delete
                            </Button>
                        </div>
                    </>
                ),
            }
        })
    }, [sortedData, query]);
}