import axiosApiInstance from "./interceptor";

export default class DictionaryServices {
    static  project = [
        {
            title: "Word",
            dataIndex: "word",
            width: "32%",
        },
        {
            title: "Definition",
            dataIndex: "definition",
        },
        {
            edit: "Edit",
            dataIndex: "edit",
        },
        {
            remove: "Remove",
            dataIndex: "remove",
        }
    ];
    static async createDictionary (word, definition) {
        return  await axiosApiInstance.post(`Add?Word=${word}&Definition=${definition}`);
    }
    static async getAllDictionary (page, limit) {
        return  await axiosApiInstance.get(`GetItems?page=${page}&limit=${limit}`);
    }
    static async updateDictionary (dictionaryItemId, word, definition) {
        return  await axiosApiInstance.post(`Update?DictionaryItemId=${dictionaryItemId}&Word=${word}&Definition=${definition}`);
    }
    static async removeDictionary (dictionaryItemId) {
        return  await axiosApiInstance.post(`Remove?DictionaryItemId=${dictionaryItemId}`);
    }
 }
