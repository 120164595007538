import React, {useState} from "react";
import {Route, useLocation} from "react-router-dom";
import {Affix, Layout} from "antd";
import Header from "../Header";
import Sider from "antd/es/layout/Sider";
import {Content} from "antd/es/layout/layout";
import LexiconAdminSidenav from "./LexiconAdminSidenav";
import LexiconAdminPage from "../../../pages/LexiconAdmin/LexiconAdminPage";
const { Header: AntHeader } = Layout;



function LexiconAdminDashboard() {
    const [sidenavColor, setSidenavColor] = useState("#1890ff");
    const [sidenavType, setSidenavType] = useState("transparent");
    const [fixed, setFixed] = useState(false);

    const handleSidenavType = (type) => setSidenavType(type);
    const handleSidenavColor = (color) => setSidenavColor(color);
    const handleFixedNavbar = (type) => setFixed(type);

    let { pathname } = useLocation();
    pathname = pathname.replace("/", "");


    return (
        <Layout
            className={`layout-dashboard ${
                pathname === "profile" ? "layout-profile" : ""
            } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
        >
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
                trigger={null}
                width={250}
                theme="light"
                className={`sider-primary ant-layout-sider-primary ${
                    sidenavType === "#fff" ? "active-route" : ""
                }`}
                style={{ background: sidenavType }}
            >
                <LexiconAdminSidenav color={sidenavColor} />
            </Sider>

            <Layout>
                {fixed ? (
                    <Affix>
                        <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                            <Header
                                name={pathname}
                                subName={pathname}
                                handleSidenavColor={handleSidenavColor}
                                handleSidenavType={handleSidenavType}
                                handleFixedNavbar={handleFixedNavbar}
                            />
                        </AntHeader>
                    </Affix>
                ) : (
                    <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                        <Header
                            name={pathname}
                            subName={pathname}
                            handleSidenavColor={handleSidenavColor}
                            handleSidenavType={handleSidenavType}
                            handleFixedNavbar={handleFixedNavbar}
                        />
                    </AntHeader>
                )}
                <Content className="content-ant" style={{marginTop: 100}}>
                    <Route  path='/lexicon-admin/dashboard' >
                        <LexiconAdminPage />
                    </Route>
                </Content>
            </Layout>
        </Layout>
    );
}
export default LexiconAdminDashboard;