import React,{useState, useEffect} from "react";
import {Card, Col, Radio, Row, Table} from "antd";
import CompanyServices from "../API/companyServices";
import {UserStructure} from "../hooks/useUser";
import {getUserPage, getUsersTotalCount} from "../utils/usersCoount";
import Pagination from "../components/pagination/pagination";
function CompanyUser () {

    const [lexiconAdmins, setLexiconAdmins] = useState([]);
    const [query, setQuery] = useState('a')
    const userDataStructured = UserStructure(lexiconAdmins, query);
    const [totalPage, setTotalPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [page, setPage] = useState(1);
    const pagesArray = getUserPage(totalPage);
    useEffect(() => {
        const response = CompanyServices.getLexiconAdmin(page,  limit);
        response.then(res => {
           setLexiconAdmins(res.data.items);
           setTotalPage(getUsersTotalCount(res.data.totalItems, limit));
        });
    }, [page]);


    const onChange = (e) => {
        setQuery(e.target.value);
    };



    return (
        <>
            <div className="layout-content">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Lexicon Admin List"
                            extra={
                                <>
                                    <Radio.Group onChange={onChange} defaultValue="a">
                                        <Radio.Button value="a">Last</Radio.Button>
                                        <Radio.Button value="b">New</Radio.Button>
                                    </Radio.Group>
                                </>
                            }
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={CompanyServices.columns}
                                    dataSource={userDataStructured}
                                    pagination={false}
                                    className="ant-border-space"
                                />
                            </div>
                            <div className="pagination__block">
                                <Pagination  pages={pagesArray} page={page} setPage={setPage}/>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default CompanyUser;