import axiosApiInstance from "./interceptor";

export default class CompanyServices {
    static  columns = [
        {
            title: "Lexicon Admin",
            dataIndex: "name",
            key: "name",
            width: "32%",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },

        {
            title: "Phone",
            key: "mobile",
            dataIndex: "mobile",
        },
        {
            title: "Location",
            key: "location",
            dataIndex: "location",
        },
        {
            title: "",
            key: "remove",
            dataIndex: "remove",
        },
    ];
    static  columnsUsers = [
        {
            title: "User",
            dataIndex: "name",
            key: "name",
            width: "32%",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },

        {
            title: "Phone",
            key: "mobile",
            dataIndex: "mobile",
        },
        {
            title: "Location",
            key: "location",
            dataIndex: "location",
        },
        {
            title: "",
            key: "remove",
            dataIndex: "remove",
        },
    ];

    static async createUser (data) {
        return  await axiosApiInstance.post('Company/AddSlaveUser', data);
    }
    static async createLexiconAdmin(data) {
        return  await axiosApiInstance.post('Company/AddSlaveAdmin', data);
    }
    static async getLexiconAdmin(page, limit = 10) {
        return  await axiosApiInstance.get(`Company/GetSlaveAdmins?page=${page}&limit=${limit}`);
    }
    static async getUsersAdmin(page, limit = 10) {
        return  await axiosApiInstance.get(`Company/GetSlaveUsers?page=${page}&limit=${limit}`);
    }
    static async changePassword(data) {
        try {
            return  await axiosApiInstance.post(`Account/ChangePassword`, data);
        } catch (e) {
            return e;
        }
    }
    static async sendEmail(email, url  = 'http://779.freelancedeveloper.site/activation') {
        return await axiosApiInstance.post(`ActivateAccount/SendActivationMail?emailAddress=${email}&redirectUrl=${url}`)
    }
    static async activate(key) {
        return await axiosApiInstance.post(`ActivateAccount/ActivateAccount`, {activateKey: key})
    }
    static async deleteCompany(accountId) {
        return  await axiosApiInstance.post(`SuperAdmin/RemoveAccount`, {accountId: accountId});
    }
}
