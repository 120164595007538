import {useMemo} from "react";


export const CompanyUsersAdmin = (users) => {
    return useMemo(() => {
        return users?.map((item, key) => {
            return  {
                title: item?.firstName,
                avatar: window.atob(item?.imageBytes),
                description: item?.description,
            }
        })
    }, [users])
}