import React, {useState} from "react";
import Row from "antd/es/descriptions/Row";
import {Alert, Button, Col, Form, Input, Table} from "antd";
import Title from "antd/es/typography/Title";
import '../../assets/styles/lexiconadmin.css'
function LexiconAdminPage () {
    const project = [
        {
            title: "Word",
            dataIndex: "word",
            width: "32%",
        },
        {
            title: "Definition",
            dataIndex: "definition",
        }
    ];
    const dataproject = [
        {
            key: "1",

            word: (
                <>
                    <div className="semibold">Lorem</div>
                </>
            ),
            definition: (
                <>
                    <div className="semibold">  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur, dolore!</div>
                </>
            )
        },

        {
            key: "2",
            word: (
                <>
                    <div className="semibold">Lorem</div>
                </>
            ),
            definition: (
                <>
                    <div className="semibold">  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur, dolore!</div>
                </>
            )
        },

        {
            key: "3",
            word: (
                <>
                    <div className="semibold">Lorem</div>
                </>
            ),
            definition: (
                <>
                    <div className="semibold">  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur, dolore!</div>
                </>
            )
        },

        {
            key: "4",
            word: (
                <>
                    <div className="semibold">Lorem</div>
                </>
            ),
            definition: (
                <>
                    <div className="semibold">  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur, dolore!</div>
                </>
            )
        },

        {
            key: "5",
            word: (
                <>
                    <div className="semibold">Lorem</div>
                </>
            ),
            definition: (
                <>
                    <div className="semibold">  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur, dolore!</div>
                </>
            )
        },

    ];
    const [flag, setFlag] = useState(false);
    const [errorMessage, setErrorMessage] = useState(true);
    const onFinish = (values) => {
        console.log("Success:", values);
        setFlag(true)
        setErrorMessage(true)
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
        setErrorMessage(false)
    };
    return (
        <>
            <div>
                {
                    flag ?  (errorMessage ? <Alert message="Success Text" type="success" />
                            : <Alert message="Error Text" type="error" />)
                        : null
                }
                <Col
                    xs={{ span: 18, offset: 0 }}
                    lg={{ span: 18, offset: 0 }}
                    md={{ span: 18 }}
                >
                    <Title className="mb-15"> Dictionary</Title>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                        className="row-col"
                    >
                        <div className="input-block">
                            <Form.Item
                                className="form-input"
                                label="words"
                                name="words"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your words!",
                                    },
                                ]}
                            >
                                <Input placeholder="Word" />
                            </Form.Item>
                            <Form.Item className="form-input" name="definition" label="Definition"
                                       rules={[
                                           {
                                               required: true,
                                               message: "Please input your Definition!",
                                           },
                                       ]}>
                                <Input.TextArea />
                            </Form.Item>
                        </div>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: "200" }}
                            >
                                Create Dictionary
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </div>
            <div >
                <Col
                    xs={{ span: 18, offset: 0 }}
                    lg={{ span: 18, offset: 0 }}
                    md={{ span: 18 }}
                >
                    <div className="table-responsive" style={{marginTop: 50}}>
                        <Table
                            columns={project}
                            dataSource={dataproject}
                            pagination={true}
                            className="ant-border-space"
                        />
                    </div>
                </Col>
            </div>

        </>
    )
}
export default LexiconAdminPage;