import axiosApiInstance from "./interceptor";

export default class ProfileServices {
    static async getMe () {
        const accountId = localStorage.getItem('accountId');
        return  await axiosApiInstance.post('Company/GetProfile', {accountId: accountId});
    }
    static async editProfile (data) {
        data.accountId = localStorage.getItem('accountId');
        return  await axiosApiInstance.post('Company/UpdateProfile', data);
    }
    static async getSuperAdmin () {
        const accountId = localStorage.getItem('accountId');
        return  await axiosApiInstance.post('SuperAdmin/GetProfile', {accountId: accountId});
    }
    static async editProfileSuperAdmin (data) {
        data.accountId = localStorage.getItem('accountId');
        return  await axiosApiInstance.post('SuperAdmin/UpdateProfile', data);
    }
    static async getUserById(accountId) {
        return   await axiosApiInstance.post('User/GetProfile', {accountId: accountId});
    }
    static async getCompanyById(accountId) {
        return   await axiosApiInstance.post('Company/GetProfile', {accountId: accountId});
    }
    static async getAdminById(accountId) {
        return   await axiosApiInstance.post('Admin/GetProfile', {accountId: accountId});
    }
}