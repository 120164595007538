import React, {useEffect, useState} from 'react';
import BgProfile from "../../assets/images/SL_042620_30310_36.jpg";
import {Avatar, Button, Card, Col, Descriptions, List, Row, Switch} from "antd";
import SuperAdminServices from "../../API/superAdminServices";
import {getUserPage, getUsersTotalCount} from "../../utils/usersCoount";
import Pagination from "../pagination/pagination";
import {CompanyUsersAdmin} from "../../hooks/useCompanyUsersAdmin";
import '../../assets/styles/style.css';





const SuperAdminCompanyProfile = () => {
    const params = new URLSearchParams(window.location.search);
    let id = params.get('id');
    const [dataUser, setDateUser] = useState({});
    const [imageURL, setImageURL] = useState('');
    const [totalPageUser, setTotalPageUser] = useState(0);
    const [totalPageAdmin, setTotalPageAdmin] = useState(0);
    const [limit, setLimit] = useState(5);
    const [pageUser, setPageUser] = useState(1);
    const [pageAdmin, setPageAdmin] = useState(1);
    const pagesArrayUser = getUserPage(totalPageUser);
    const pagesArrayAdmin = getUserPage(totalPageAdmin);
    const [user, setUser] = useState(null);
    const [admin, setAdmin] = useState(null);
    const userData  = CompanyUsersAdmin(user);
    const adminData  = CompanyUsersAdmin(admin);

    useEffect(async () => {
        const response = await SuperAdminServices.getCompanyIdAdmin(id);
        setDateUser(response.data.item);
        setImageURL(window.atob(response.data.item.imageBytes));
    }, []);


    useEffect(async () => {
        const responseUser = await SuperAdminServices.getCompanyUser(id, pageUser, limit);
        setUser(responseUser.data.items);
        setTotalPageUser(getUsersTotalCount(responseUser.data.totalItems, limit));
    }, [pageUser]);

    useEffect( async () => {
        const responseAdmin = await SuperAdminServices.getCompanyLexiconAdmin(id, pageAdmin, limit);
        console.log(responseAdmin);
        setAdmin(responseAdmin.data.items);
        setTotalPageAdmin(getUsersTotalCount(responseAdmin.data.totalItems, limit));
    }, [pageAdmin])




    return (
        <>
            <div
                className="profile-nav-bg"
                style={{backgroundImage: "url(" + BgProfile + ")"}}
            />

            <Card
                className="card-profile-head"
                bodyStyle={{display: "none"}}
                title={
                    <Row justify="space-between" align="middle" gutter={[24, 0]}>
                        <Col span={24} md={12} className="col-info">
                            <Avatar.Group>
                                <Avatar size={74} shape="square" src={imageURL}/>

                                <div className="avatar-info">
                                    <h4 className="font-semibold m-0">{dataUser?.companyName}</h4>
                                    <p>Company</p>
                                </div>
                            </Avatar.Group>
                        </Col>
                    </Row>
                }
            />

            <Row gutter={[24, 0]}>
                <Col span={24} md={12} className="mb-24 ">
                    <Card
                        bordered={false}
                        className="header-solid h-full"
                        title={<h6 className="font-semibold m-0">Platform Settings</h6>}
                    >
                        <Descriptions title="Oliver Liam">
                            <Descriptions.Item label="Full Name" span={3}>
                                {dataUser?.companyName}
                            </Descriptions.Item>
                            <Descriptions.Item label="Mobile" span={3}>
                                {dataUser?.mobile}
                            </Descriptions.Item>
                            <Descriptions.Item label="Email" span={3}>
                                {dataUser?.email}
                            </Descriptions.Item>
                            <Descriptions.Item label="Location" span={3}>
                                {dataUser?.address}
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
                <Col span={24} md={12} className="mb-24">
                    <Card
                        bordered={false}
                        title={<h6 className="font-semibold m-0">Profile Information</h6>}
                        className="header-solid h-full card-profile-information"
                        // extra={<Button type="link">{pencil}</Button>}
                        bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                    >
                        <p className="text-dark">
                            {" "}
                            {dataUser?.description}
                            {" "}
                        </p>
                        <hr className="my-25" />

                    </Card>
                </Col>

            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24} md={12} className="mb-24">
                    <Card
                        bordered={false}
                        title={<h6 className="font-semibold m-0">Users</h6>}
                        className="header-solid h-full"
                        bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                    >
                        <List
                            itemLayout="horizontal"
                            dataSource={userData}
                            split={false}
                            className="conversations-list"
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar shape="square" size={48} src={item.avatar} />
                                        }
                                        title={item.title}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                        <div className="pagination__block">
                            <Pagination  pages={pagesArrayUser} page={pageUser} setPage={setPageUser}/>
                        </div>
                    </Card>
                </Col>
                <Col span={24} md={12} className="mb-24">
                    <Card
                        bordered={false}
                        title={<h6 className="font-semibold m-0">Lexicon Admin</h6>}
                        className="header-solid h-full"
                        bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                    >
                        <List
                            itemLayout="horizontal"
                            dataSource={adminData}
                            split={false}
                            className="conversations-list"
                            renderItem={(item) => (
                                <List.Item >
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar shape="square" size={48} src={item.avatar} />
                                        }
                                        title={item.title}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                        <div className="pagination__block">
                            <Pagination  pages={pagesArrayAdmin} page={pageAdmin} setPage={setPageAdmin}/>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default SuperAdminCompanyProfile;