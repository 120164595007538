import {useMemo} from "react";

export const DictionaryStructure = (dictionary, setEditData, setEditFlag, setDeletedId) => {
    return useMemo(() => {
        return dictionary.map((item, key) => {
            return {
                key: key + 1,
                word: (
                        <>
                            <div className="semibold">{item.word}</div>
                        </>
                ),
                definition: (
                        <>
                            <div className="semibold">{item.definition}</div>
                        </>
                ),
                edit: (
                    <>
                        <div className="semibold" style={{cursor: 'pointer'}}
                            onClick={(evt) => {
                                evt.preventDefault();
                                setEditData(item);
                                setEditFlag(true)
                            }}
                        >Edit</div>
                    </>
                ),
                remove: (
                    <>
                        <div className="semibold" style={{cursor: 'pointer'}}
                             onClick={(evt) => {
                                 evt.preventDefault();
                                 setDeletedId(item.dictionaryItemId)
                             }}
                        >Remove</div>
                    </>
                ),
            }
        })
    }, [dictionary])
}