
import axiosApiInstance from "./interceptor";


export default class AuthServices {
    static async register (data) {
        return await axiosApiInstance.post(`Company/Register`, data);
    }

    static async login (data) {
        return await axiosApiInstance.post(`Account/Authorize`, data);
    }
}

