import React, {useState} from 'react';
import Title from "antd/es/typography/Title";
import {Button, Col, Form, Input, Row} from "antd";
import {useLocation, useHistory} from "react-router-dom";
import CompanyServices from "../API/companyServices";
import '../assets/styles/activation.css'

import {Content} from "antd/es/layout/layout";
import AuthServices from "../API/authServices";


const ActivationAccount = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [oldPassword, setOldPassword] = useState(null);
    const search = useLocation().search;
    let history = useHistory();


    const activateCode = async () => {
        setIsLoading(true);
        setSuccess(false)
        const key = new URLSearchParams(search).get('ActivateKey');
        const response = await CompanyServices.activate(key);
        if (response) {
            setOldPassword(response.data.item.temporaryPassword);
            const responseUser = await AuthServices.login({
                email: response.data.item.email,
                password: response.data.item.temporaryPassword
            });
            if (responseUser) {
                localStorage.setItem('token', responseUser.data.item.jwtToken);
                localStorage.setItem('refresh__token', responseUser.data.item.refreshToken);
                localStorage.setItem('accountId', responseUser.data.item.accountId);
                localStorage.setItem('role', responseUser.data.item.role.toString());
                setIsLoading(false);
                setSuccess(true);
            }
        }
    }

    const  goToHome = (e) => {
        e.preventDefault();
        history.push(`/sign-in`);
    }

    const onFinish = async (values) => {
        setIsLoading(true);
        delete values.confirmPassword;
        values.oldPassword = oldPassword;
        const response = await CompanyServices.changePassword(values);
        if (response) {
            console.log(response);
            setIsLoading(false);
            history.push(`/sign-in`);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div>
            { !success ?
                <>
                    <Title className="mb-15 text-center" style={{marginTop: 200, marginBottom: 100}}>
                        Congratulations you are invited. For activating your account click here.
                    </Title>
                    <Button
                        type="primary"
                        htmlType="button"
                        style={{ width: "200", margin: '80px auto', display: 'block' }}
                        disabled={isLoading}
                        onClick={activateCode}
                    >
                        Activate
                    </Button>
                </> :
                <Content >
                    <Row gutter={[24, 0]} justify="space-around">
                        <Col
                            style={{marginTop: 200}}
                            xs={{ span: 6, offset: 0 }}
                            lg={{ span: 6, offset: 0 }}
                            md={{ span: 6 }}
                        >
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            layout="vertical"
                            className="row-col"

                        >

                            <Form.Item
                                className="change"
                                label="New Password"
                                name="newPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your password!",
                                    },
                                ]}
                            >
                                <Input placeholder="Password" type="password" />
                            </Form.Item>

                            <Form.Item
                                className="change"
                                label="Confirm Password"
                                name="confirmPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Confirm Password!",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input placeholder="Confirm Password" type="password" />
                            </Form.Item>


                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ width: "100%" }}
                                    disabled={isLoading}
                                >
                                    Change Password
                                </Button>
                            </Form.Item>
                        </Form>
                        </Col>
                    </Row>
                </Content>
                // <div className='account__info' style={{
                //     display: 'block',
                //     margin: 'auto',
                //     width: 'fit-content',
                // }}>
                //     <p>Email: <strong style={{marginLeft: 20}}>{accountData?.email}</strong></p>
                //     <p>Password: <strong>{accountData?.temporaryPassword}</strong></p>
                //     <Button
                //         type="primary"
                //         htmlType="button"
                //         style={{ width: "200", margin: '80px auto', display: 'block' }}
                //         disabled={isLoading}
                //         onClick={(e) => goToHome(e)}
                //     >
                //         Sign In
                //     </Button>
                // </div>
            }
        </div>
    );
};

export default ActivationAccount;