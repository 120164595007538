import {Switch, Route, Redirect, BrowserRouter} from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import CompanyDashboard from "./components/layout/CompanyDashboard";
import LexiconAdminDashboard from "./components/layout/LexiconAdmin/LexiconAdminDashboard";
import SuperAdmin from "./components/super-admin/SuperAdmin";
import ActivationAccount from "./pages/ActivationAccount";

function App() {
  return (
    <div className="App">
            <Switch>
                <Redirect exact from="/" to="/sign-up" />
                <Route path="/sign-up" exact component={SignUp} />
                <Route path="/sign-in"  component={SignIn} />
                <Route path="/activation" exact component={ActivationAccount} />
                <Route path='/company-organization'  component={CompanyDashboard} />
                <Route path='/lexicon-admin'  component={LexiconAdminDashboard} />
                <Route path='/superAdmin'  component={SuperAdmin} />
                <Main>
                    <Route exact  path='/Organization' component={Home} />
                    <Route exact path="/tables" component={Tables} />
                    <Route exact path="/billing" component={Billing} />
                    <Route exact path="/rtl" component={Rtl} />
                    <Route exact path="/profile" component={Profile} />
                </Main>
            </Switch>
    </div>
  );
}
export default App;
