import React, {useEffect, useRef, useState} from "react";
import {Alert, Button, Col, Form, Input, Progress, Row, Select, Table} from "antd";
import Title from "antd/es/typography/Title";
import DictionaryServices from "../API/dictionaryServices";
import {DictionaryStructure} from "../hooks/useDictionary";
import Pagination from "../components/pagination/pagination";
import {getUserPage, getUsersTotalCount} from "../utils/usersCoount";




function CompanyDictionary () {
    const formRef = useRef('');
    const [flag, setFlag] = useState(false);
    const [errorMessage, setErrorMessage] = useState(true);
    const [editFlag, setEditFlag] = useState(false);
    const [dictionaryData, setDictionaryData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [editData, setEditData] = useState({
        word: '',
        definition: '',
        dictionaryItemId: ''
    });
    const [deletedId, setDeletedId] = useState('');
    const dictionaryDataStructured = DictionaryStructure(
        dictionaryData,
        setEditData,
        setEditFlag,
        setDeletedId
    );
    const pagesArray = getUserPage(totalPage);
    useEffect(async () => {
        const response = await DictionaryServices.getAllDictionary(page, limit);
        setDictionaryData(response.data.items);
        setTotalPage(getUsersTotalCount(response.data.totalItems, limit));
    }, [page]);


    useEffect(() => {
       formRef.current.setFieldsValue({
           words: editData.word,
           definition: editData.definition
       });
    }, [editData])

    useEffect( async () => {
        if (deletedId) {
            const response = await DictionaryServices.removeDictionary(deletedId);
            setDictionaryData([...dictionaryData].filter(item => item.dictionaryItemId !== response.data.item.dictionaryItemId))
        }
    }, [deletedId]);




    const onFinish = async (values) => {
        if (!editFlag) {
            setIsLoading(true);
            const response = await DictionaryServices.createDictionary(values.words, values.definition);
            console.log(formRef);
            formRef.current.resetFields();
            setDictionaryData([...dictionaryData, {word: values.words, definition: values.definition}]);
            if (response) setIsLoading(false)
        } else {
            setIsLoading(true);
            const response = await DictionaryServices.updateDictionary(editData.dictionaryItemId, values.words, values.definition)
            if (response) setIsLoading(false)
        }
    };

    const onFinishFailed =  (errorInfo) => {
        setErrorMessage(false)
    };
    return (
        <>
            <Row gutter={[24, 0]} justify="space-around">
                {
                    flag ?  (errorMessage ? <Alert message="Success Text" type="success" />
                            : <Alert message="Error Text" type="error" />)
                        : null
                }
                <Col
                    xs={{ span: 18, offset: 0 }}
                    lg={{ span: 18, offset: 0 }}
                    md={{ span: 18 }}
                >
                    <Title className="mb-15"> Dictionary</Title>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                        className="row-col"
                        ref={formRef}
                    >
                        <div className="input-block">
                            <Form.Item
                                className="form-input"
                                label="words"
                                name="words"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your words!",
                                    },
                                ]}
                            >
                                <Input placeholder="Word" />
                            </Form.Item>
                            <Form.Item className="form-input" name="definition" label="Definition"
                                       rules={[
                                           {
                                               required: true,
                                               message: "Please input your Definition!",
                                           },
                                       ]}>
                                <Input.TextArea  />
                            </Form.Item>
                        </div>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: "200" }}
                                disabled={isLoading}
                            >
                                {!editFlag ? 'Create Definition' : 'Edit Definition'}
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row gutter={[24, 0]} justify="space-around">
                <Col
                    xs={{ span: 18, offset: 0 }}
                    lg={{ span: 18, offset: 0 }}
                    md={{ span: 18 }}
                >
                    <div className="table-responsive" style={{marginTop: 50}}>
                        <Table
                            columns={DictionaryServices.project}
                            dataSource={dictionaryDataStructured}
                            pagination={false}
                            className="ant-border-space"
                        />
                    </div>
                    <div className="pagination__block">
                        <Pagination  pages={pagesArray} page={page} setPage={setPage}/>
                    </div>
                </Col>
            </Row>


        </>
        )
}

export default CompanyDictionary;