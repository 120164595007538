import React, {useState} from "react";
import {Button, Col, Form, Input, Row, Select, Upload} from "antd";
import { Alert } from 'antd';
import Title from "antd/es/typography/Title";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import { USER} from "../interface";
import CompanyServices from "../API/companyServices";
function CompanyCreateUser () {
    const [message, setMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(true);
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState('1');
    const [form] = Form.useForm();


    const onFinish =  (values) => {
        values.imageBytes = window.btoa(imageUrl);
        if (role === USER) {
            setLoading(true);
            const response = CompanyServices.createUser(values);
            response.then(res => {
                form.resetFields();
                setImageUrl('');
                console.log('User', res);
                setLoading(false)
            })
        } else {
            setLoading(true);
            const response = CompanyServices.createLexiconAdmin(values);
            response.then(res => {
                form.resetFields();
                setImageUrl('');
                console.log('Lexicon Admin', res)
                setLoading(false)
            })

        }
        setMessage(true)
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
        setErrorMessage(false)
    };
    function onChange(value) {
        setRole(value);
    }

    function getUserBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        console.log(file)
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!').then();
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!').then();
        }
        return isJpgOrPng && isLt2M;
    }

    const customRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            console.log(file)
            onSuccess("ok");
        }, 0);
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            console.log('uploading', info);
        }
        if (info.file.status === 'done') {
            setLoading(false)
            getUserBase64(info.file.originFileObj, imageUrl =>
                setImageUrl(imageUrl)
            );
        }
    };



    const uploadButton = (
        <div>
            { loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );


    return (
        <Row gutter={[24, 0]} justify="space-around">
            {
                message ?  (errorMessage ? <Alert message="Success Text" type="success" />
                        : <Alert message="Error Text" type="error" />)
                    : null
            }


            <Col
                xs={{ span: 18, offset: 0 }}
                lg={{ span: 18, offset: 0 }}
                md={{ span: 18 }}
            >
                <Title className="mb-15">Create User</Title>
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader text-center"
                    showUploadList={false}
                    customRequest={customRequest}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    className="row-col"
                    form={form}
                >
                    <div className="input-block">
                        <Form.Item
                            className="form-input"
                            label="First Name"
                            name="firstname"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your First Name!",
                                },
                            ]}
                        >
                            <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item
                            className="form-input"
                            label="Last Name"
                            name="lastname"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your Last Name!",
                                },
                            ]}
                        >
                            <Input placeholder="Last Name" />
                        </Form.Item>
                    </div>

                    <div className="input-block">
                        <Form.Item
                            className="form-input"
                            label="Mobile"
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your  Mobile!",
                                },
                            ]}
                        >
                            <Input placeholder="Mobile" />
                        </Form.Item>
                        <Form.Item
                            className="form-input"
                            label="Location"
                            name="location"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your Location!",
                                },
                            ]}
                        >
                            <Input placeholder="Location" />
                        </Form.Item>
                    </div>

                    <div className="input-block">
                        <Form.Item
                            className="form-input"
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your  Email!",
                                },
                            ]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Select
                            onChange={onChange}
                            placeholder="Select a Role"
                        >
                            <Select.Option value="2">Lexicon Admin</Select.Option>
                            <Select.Option value="3">User</Select.Option>
                        </Select>,
                    </div>
                    <div className="input-block">
                        <Form.Item className="form-input"  name="description" label="Description"
                                   rules={[
                                       {
                                           required: true,
                                           message: "Please input your Description!",
                                       },
                                   ]}>
                            <Input.TextArea rows={5} />
                        </Form.Item>
                    </div>


`

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ width: "200" }}
                            disabled={loading}
                        >
                            Create User
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}
export default CompanyCreateUser;