import React, {useState} from 'react';
import './changePassword.css';
import {Button, Form, Input} from "antd";
import CompanyServices from "../../API/companyServices";
const ChangePassword = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const onChangePassword = async (values) => {
        setIsLoading(true);
        delete values.confirmPassword;
        const response = await CompanyServices.changePassword(values);
        if (response.errorMessage) {
            setError(response.errorMessage)
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <h3 style={{color: 'red'}}>{error}</h3>}
            <Form
                onFinish={onChangePassword}
                layout="vertical"
                className="row-col"
                style={{marginTop: 40}}
            >
                <div className="input-block">
                    <Form.Item
                        className="form-input"
                        label="Current password"
                        name="oldPassword"
                        rules={[
                            {
                                required: true,
                                message: "Please input your Current Password!",
                            },
                        ]}
                    >
                        <Input placeholder="Current Password" type='password' />
                    </Form.Item>
                    <Form.Item
                        className="form-input"
                        label="New Password"
                        name="newPassword"
                        rules={[
                            {
                                required: true,
                                message: "Please input your New Password!",
                            }
                        ]}
                    >
                        <Input placeholder="New Password" type='password' />
                    </Form.Item>

                </div>
                <Form.Item
                    className="form-input"
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[
                        {
                            required: true,
                            message: "Please input your Confirm Password!",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input placeholder="Confirm Password" type='password' />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "200" }}
                        disabled={isLoading}
                    >
                        Change Password
                    </Button>
                </Form.Item>
            </Form>
        </>

    );
};

export default ChangePassword;