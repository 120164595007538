import React from 'react';
import './pagination.css';
const Pagination = ({pages, page, setPage}) => {
    return (
        <div className="pagination">
            {
                pages.map(item =>
                    <span
                        key={item}
                        onClick={() => setPage(item)}
                        className={page === item ? 'page page__current':  'page'}>
                        {item}
                    </span>
                )
            }
        </div>
    );
};

export default Pagination;