import React, {useEffect, useState} from "react";
import  {Alert, Button, Col, Form, Input, Row } from "antd";
import Title from "antd/es/typography/Title";

import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ProfileServices from "../API/profileServices";
import Global from "../config/global";
import ChangePassword from "../components/change-password/changePassword";


function CompanyProfile () {
    const [messageSuccess, setMessageSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(true);
    const [imageUrl, setImageUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const [accountData, setAccountData] = useState({});
    const [form] = Form.useForm();
    useEffect(async () => {
        if (localStorage.getItem('role') ===  Global.SUPER_ADMIN) {
            const response =  await  ProfileServices.getSuperAdmin();
            setAccountData(response.data.item);
        } else if (localStorage.getItem('role') ===  Global.COMPANY) {
            const response = await ProfileServices.getMe();
            console.log(response.data.item)
            setAccountData(response.data.item);
        }
    }, [])

    useEffect(() => {
        if (accountData.imageBytes) {
            setImageUrl(window.atob(accountData.imageBytes))
        }
        form.setFieldsValue({
            email: accountData.email,
            companyName: accountData.companyName,
            companyPlan: accountData.companyPlan,
            description: accountData.description,
            mobile: accountData.mobile,
            address: accountData.address
        })
    }, [accountData])

    const onFinish = async (values) => {
        values.imageBytes = window.btoa(imageUrl);
        if (localStorage.getItem('role') ===  Global.SUPER_ADMIN) {
            setLoading(true)
            const response = await ProfileServices. editProfileSuperAdmin(values);
            if (response) setLoading(false)
        } else if (localStorage.getItem('role') ===  Global.COMPANY) {
            setLoading(true)
            const response = await ProfileServices.editProfile(values);
            if (response) setLoading(false)
        }
        setMessageSuccess(true)
        setErrorMessage(true)
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
        setErrorMessage(false)
    };


    const uploadButton = (
        <div>
            { loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    function beforeUpload(file) {
        console.log(file)
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!').then();
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!').then();
        }
        return isJpgOrPng && isLt2M;
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            console.log('uploading', info);
        }
        if (info.file.status === 'done') {
            setLoading(false)
            getBase64(info.file.originFileObj, imageUrl =>
                setImageUrl(imageUrl)
            );
        }
    };
    const customRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            console.log(file)
            onSuccess("ok");
        }, 0);
    }
    return (
        <Row gutter={[24, 0]} justify="space-around">
            {
                messageSuccess ?  (errorMessage ? <Alert message="Success Text" type="success" />
                        : <Alert message="Error Text" type="error" />)
                    : null
            }

            <Col
                xs={{ span: 18, offset: 0 }}
                lg={{ span: 18, offset: 0 }}
                md={{ span: 18 }}
            >
                <Title className="mb-15 text-center" style={{marginBottom: 40}}>Profile</Title>
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader text-center"
                    showUploadList={false}
                    customRequest={customRequest}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
                <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    className="row-col"
                    style={{marginTop: 40}}
                >
                    <div className="input-block">
                        <Form.Item
                            className="form-input"
                            label="Company Name"
                            name="companyName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your Company Name!",
                                },
                            ]}
                        >
                            <Input placeholder="Company Name" />
                        </Form.Item>
                        <Form.Item
                            className="form-input"
                            label="Company Plan"
                            name="companyPlan"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your Company Plan!",
                                },
                            ]}
                        >
                            <Input placeholder="Company Plan" />
                        </Form.Item>
                    </div>

                    <div className="input-block">
                        <Form.Item
                            className="form-input"
                            label="Mobile"
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your  Mobile!",
                                },
                            ]}
                        >
                            <Input placeholder="Mobile" />
                        </Form.Item>
                        <Form.Item
                            className="form-input"
                            label="Address"
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your Address!",
                                },
                            ]}
                        >
                            <Input placeholder="Address" />
                        </Form.Item>
                    </div>

                    <div className="input-block">
                        <Form.Item
                            className="form-input"
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your  Email!",
                                },
                            ]}
                        >
                            <Input placeholder="Email"  disabled  />
                        </Form.Item>
                        <Form.Item className="form-input" name="description" label="Description"
                                   rules={[
                                       {
                                           required: true,
                                           message: "Please input your Description!",
                                       },
                                   ]}>
                            <Input.TextArea  placeholder="Description"/>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ width: "200" }}
                            disabled={loading}
                        >
                            Edit
                        </Button>
                    </Form.Item>
                </Form>

                <ChangePassword/>



            </Col>
        </Row>
    )
}
export default CompanyProfile;