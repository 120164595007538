import React, {useState, useEffect} from "react";
import BgProfile from "../assets/images/SL_042620_30310_36.jpg";
import {Avatar, Button, Card, Col, Descriptions, List, Row} from "antd";
import ProfileServices from "../API/profileServices";
import Global from "../config/global";
import CompanyServices from "../API/companyServices";
function UserProfile () {
    const [imageURL, setImageURL] = useState('');
    const [loading ,setLoading] = useState(false);
    const [dataUser, setDateUser] = useState({});

    useEffect(async () => {
        const params = new URLSearchParams(window.location.search);
        let id = params.get('id');
        let role = params.get('role');
        if (role === Global.USER) {
            const response = await ProfileServices.getUserById(id);
            setDateUser(response.data.item);
            setImageURL(window.atob(response.data.item.imageBytes))
        } else if (role === Global.COMPANY) {
            const response = await ProfileServices.getCompanyById(id);
            setDateUser(response.data.item);
            setImageURL(window.atob(response.data.item.imageBytes));
        } else {
            const response = await ProfileServices.getAdminById(id);
            setDateUser(response.data.item);
            setImageURL(window.atob(response.data.item.imageBytes))
        }
    }, []);

    const sendEmail = async (evt) => {
        setLoading(true);
        evt.preventDefault();
        const response = await CompanyServices.sendEmail(dataUser.email);
        if (response) setLoading(false)
        console.log(response);
    }




    return (
        <>
            <div
                className="profile-nav-bg"
                style={{backgroundImage: "url(" + BgProfile + ")"}}
            />

            <Card
                className="card-profile-head"
                bodyStyle={{display: "none"}}
                title={
                    <Row justify="space-between" align="middle" gutter={[24, 0]}>
                        <Col span={24} md={12} className="col-info">
                            <Avatar.Group>
                                <Avatar size={74} shape="square" src={imageURL}/>

                                <div className="avatar-info">
                                    <h4 className="font-semibold m-0">{dataUser?.firstName}</h4>
                                    {dataUser.role === 1  ? <p>Company</p>: null}
                                    {dataUser.role === 2  ? <p>Lexicon Admin</p>: null}
                                    {dataUser.role === 3  ? <p>User</p>: null}
                                </div>
                            </Avatar.Group>
                        </Col>

                    </Row>
                }
            />
            <Row gutter={[24, 0]}>
                <Col span={24} md={12} className="mb-24 ">
                    <Card
                        bordered={true}
                        className="header-solid h-full"
                    >
                        <Descriptions title={(dataUser?.firstName ? dataUser?.firstName : dataUser?.companyName)
                        + ' ' + (dataUser?.lastName ? dataUser?.lastName : '')}>
                            <Descriptions.Item label="Full Name" span={3}>
                                {dataUser?.firstName ? dataUser?.firstName : dataUser?.companyName}
                            </Descriptions.Item>
                            <Descriptions.Item label="Mobile" span={3}>
                                {dataUser?.mobile}
                            </Descriptions.Item>
                            <Descriptions.Item label="Email" span={3}>
                                {dataUser?.email}
                            </Descriptions.Item>
                            <Descriptions.Item label="Location" span={3}>
                                {dataUser?.location ? dataUser?.location : dataUser?.address}
                            </Descriptions.Item>
                        </Descriptions>

                        <Button
                            type="primary"
                            htmlType="button"
                            style={{ width: "200", marginTop: 100}}
                            onClick={sendEmail}
                            disabled={loading}
                        >
                            Send Email
                        </Button>
                    </Card>
                </Col>
                <Col span={24} md={12} className="mb-24">
                    <Card
                        bordered={false}
                        title={<h6 className="font-semibold m-0">Profile Information</h6>}
                        className="header-solid h-full card-profile-information"
                        bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                    >
                        <p className="text-dark">
                            {" "}
                            {dataUser.description ? dataUser.description : 'Not Description'}
                            {" "}
                        </p>
                        <hr className="my-25" />
                    </Card>
                </Col>
            </Row>
        </>
    );
}
export default UserProfile;