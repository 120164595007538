
import React, {Component} from "react";
import {
  Layout,
  Button,
  Typography,
  Card,
  Form,
  Input,
  Checkbox,
} from "antd";


import {Link} from "react-router-dom";
import AuthServices from "../API/authServices";


const { Title } = Typography;
const { Content } = Layout;





export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true,
    }
  }

  render() {
    const onFinish = (values) => {
      const response = AuthServices.register(values);
      response.then(res => {
        console.log("Success:", res);
        this.props.history.push("/sign-in");
      });
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    return (
      <>
        <div className="layout-default ant-layout layout-sign-up">
          <Content className="p-0">
            <div className="sign-up-header">
              <div className="content">
                <Title>Sign Up</Title>
                <p className="text-lg">
                 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum, soluta.
                </p>
              </div>
            </div>

            <Card
              className="card-signup header-solid h-full ant-card pt-0"
              title={<h5>Register</h5>}
              bordered="false"
            >
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="row-col"
              >
                <Form.Item
                  name="Name"
                  style={{marginBottom: 20}}
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                  name="email"
                  style={{marginBottom: 20}}
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input placeholder="email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input type='password' placeholder="Password" />
                </Form.Item>




                <Form.Item name="remember"  >
                  <Checkbox
                      onChange={(e) =>
                          this.state.flag = e.target.checked
                      }
                      checked={this.state.flag}
                  >
                    I agree the{" "}
                    <a href="#pablo" className="font-bold text-dark">
                      Terms and Conditions
                    </a>
                  </Checkbox>
                </Form.Item>


                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    SIGN UP
                  </Button>
                </Form.Item>
              </Form>
              <p className="font-semibold text-muted text-center">
                Already have an account?{" "}
                <Link to="/sign-in" className="font-bold text-dark">
                  Sign In
                </Link>
              </p>
              {
                !this.state.flag &&  <span className='error-message-agree'>You dont agree Terms and Conditions</span>
              }
            </Card>
          </Content>
        </div>
      </>
    );
  }
}
