import {
  Card,
  Col,
  Row,
  Typography,
  Button,
  Radio, Avatar, Table,
} from "antd";

import face2 from "../assets/images/face-2.jpg";
import face3 from "../assets/images/face-3.jpg";
import face from "../assets/images/face-1.jpg";
import face4 from "../assets/images/face-4.jpg";
import face5 from "../assets/images/face-5.jpeg";
import face6 from "../assets/images/face-6.jpeg";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {getUserPage, getUsersTotalCount} from "../utils/usersCoount";
import Pagination from "../components/pagination/pagination";
import SuperAdminServices from "../API/superAdminServices";
import {CompanyStructure} from "../hooks/useCompany";

function Home() {
    const { Title } = Typography;
    const [companyData, setCompanyData] = useState([]);
    const [query, setQuery] = useState('a');
    const userDataStructured = CompanyStructure(companyData, query);
    const [totalPage, setTotalPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [page, setPage] = useState(1);
    const pagesArray = getUserPage(totalPage);
    useEffect(() => {
        const response = SuperAdminServices.getCompanyAdmin(page, limit);
        response.then(res => {
            setCompanyData(res.data.items);
            setTotalPage(getUsersTotalCount(res.data.totalItems, limit));
        })
    }, [page]);
    const onChange = (e) => {
        setQuery(e.target.value);
    };


  // table code start
  const columns = [
    {
      title: "COMPANY",
      dataIndex: "name",
      key: "name",
      width: "32%",
    },
    {
      title: "COMPANY EMAIL",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "COMPANY MOBILE",
      key: "mobile",
      dataIndex: "mobile",
    },
    {
      title: "COMPANY LOCATION",
      key: "location",
      dataIndex: "location",
    },
      {
          title: "",
          key: "remove",
          dataIndex: "remove",
      },
  ];

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Company List"
                extra={
                  <>
                    <Radio.Group onChange={onChange} defaultValue="a">
                      <Radio.Button value="a">All</Radio.Button>
                      <Radio.Button value="b">Last</Radio.Button>
                    </Radio.Group>
                  </>
                }
            >
              <div className="table-responsive">
                <Table
                    columns={columns}
                    dataSource={userDataStructured}
                    pagination={false}
                    className="ant-border-space"
                />
              </div>
                <div className="pagination__block">
                    <Pagination  pages={pagesArray} page={page} setPage={setPage}/>
                </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
