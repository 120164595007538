import React, {useState, useEffect} from "react";
import {Route, useLocation} from "react-router-dom";
import {Affix, Layout, Switch} from "antd";
import Header from "./Header";
import Sider from "antd/es/layout/Sider";
import {Content} from "antd/es/layout/layout";
import CompanySidenav from "./CompanySidenav";
import CompanyAdminDashboard from "../../pages/CompanyAdminDashboard";
import UserProfile from "../../pages/UserProfile";
import CompanyUser from "../../pages/CompanyUser";
import CompanyCreateUser from "../../pages/CompanyCreateUser";
import CompanyDictionary from "../../pages/CompanyDictionary";
import CompanyProfile from "../../pages/CompanyProfile";
const { Header: AntHeader } = Layout;



function CompanyDashboard() {
    const [sidenavColor, setSidenavColor] = useState("#1890ff");
    const [sidenavType, setSidenavType] = useState("transparent");
    const [fixed, setFixed] = useState(false);

    const handleSidenavType = (type) => setSidenavType(type);
    const handleSidenavColor = (color) => setSidenavColor(color);
    const handleFixedNavbar = (type) => setFixed(type);

    let { pathname } = useLocation();
    pathname = pathname.replace("/", "");


    return (
        <Layout
            className={`layout-dashboard ${
                pathname === "profile" ? "layout-profile" : ""
            } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
        >
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
                trigger={null}
                width={250}
                theme="light"
                className={`sider-primary ant-layout-sider-primary ${
                    sidenavType === "#fff" ? "active-route" : ""
                }`}
                style={{ background: sidenavType }}
            >
                <CompanySidenav color={sidenavColor} />
            </Sider>

            <Layout>
                {fixed ? (
                    <Affix>
                        <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                            <Header
                                name={pathname}
                                subName={pathname}
                                handleSidenavColor={handleSidenavColor}
                                handleSidenavType={handleSidenavType}
                                handleFixedNavbar={handleFixedNavbar}
                            />
                        </AntHeader>
                    </Affix>
                ) : (
                    <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                        <Header
                            name={pathname}
                            subName={pathname}
                            handleSidenavColor={handleSidenavColor}
                            handleSidenavType={handleSidenavType}
                            handleFixedNavbar={handleFixedNavbar}
                        />
                    </AntHeader>
                )}
                <Content className="content-ant" style={{marginTop: 100}}>
                    <Route  path='/company-organization/dashboard' >
                            <CompanyAdminDashboard />
                    </Route>
                    <Route  path='/company-organization/profile' >
                            <UserProfile />
                    </Route>
                    <Route  path='/company-organization/user-list' >
                            <CompanyUser />
                    </Route>
                    <Route  path='/company-organization/create-user' >
                            <CompanyCreateUser />
                    </Route>
                    <Route  path='/company-organization/dictionary' >
                            <CompanyDictionary />
                    </Route>
                    <Route  path='/company-organization/company-profile' >
                            <CompanyProfile />
                    </Route>
                </Content>
            </Layout>
        </Layout>
    );
}
export default CompanyDashboard;