
import {useEffect} from "react";

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Switch,

} from "antd";


import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import convesionImg from "../assets/images/face-3.jpg";
import convesionImg2 from "../assets/images/face-4.jpg";
import convesionImg3 from "../assets/images/face-5.jpeg";
import convesionImg4 from "../assets/images/face-6.jpeg";
import convesionImg5 from "../assets/images/face-2.jpg";




function Profile() {

  useEffect(  () => {
    const params = new URLSearchParams(window.location.search)
    let id = params.get('id');

  }, [])




  const data = [
    {
      title: "Sophie B.",
      avatar: convesionImg,
      description: "Hi! I need more information…",
    },
    {
      title: "Anne Marie",
      avatar: convesionImg2,
      description: "Awesome work, can you…",
    },
    {
      title: "Ivan",
      avatar: convesionImg3,
      description: "About files I can…",
    },
    {
      title: "Peterson",
      avatar: convesionImg4,
      description: "Have a great afternoon…",
    },
    {
      title: "Nick Daniel",
      avatar: convesionImg5,
      description: "Hi! I need more information…",
    },
  ];



  return (
    <>
      <div
    className="profile-nav-bg"
    style={{backgroundImage: "url(" + BgProfile + ")"}}
    />

      <Card
    className="card-profile-head"
    bodyStyle={{display: "none"}}
    title={
      <Row justify="space-between" align="middle" gutter={[24, 0]}>
        <Col span={24} md={12} className="col-info">
          <Avatar.Group>
            <Avatar size={74} shape="square" src={profilavatar}/>

            <div className="avatar-info">
              <h4 className="font-semibold m-0">Sarah Jacob</h4>
              <p>Founder</p>
            </div>
          </Avatar.Group>
        </Col>
      </Row>
    }
    />

      <Row gutter={[24, 0]}>
        <Col span={24} md={12} className="mb-24 ">
          <Card
            bordered={false}
            className="header-solid h-full"
            title={<h6 className="font-semibold m-0">Platform Settings</h6>}
          >
            <ul className="list settings-list">
              <li>
                <h6 className="list-header text-sm text-muted">ACCOUNT</h6>
              </li>
              <li>
                <Switch defaultChecked />

                <span>Email me when someone follows me</span>
              </li>
              <li>
                <Switch />
                <span>Email me when someone answers me</span>
              </li>
              <li>
                <Switch defaultChecked />
                <span>Email me when someone mentions me</span>
              </li>
              <li>
                <h6 className="list-header text-sm text-muted m-0">
                  APPLICATION
                </h6>
              </li>
              <li>
                <Switch defaultChecked />
                <span>New launches and projects</span>
              </li>
              <li>
                <Switch defaultChecked />
                <span>Monthly product updates</span>
              </li>
              <li>
                <Switch defaultChecked />
                <span>Subscribe to newsletter</span>
              </li>
            </ul>
          </Card>
        </Col>
        <Col span={24} md={12} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Profile Information</h6>}
            className="header-solid h-full card-profile-information"
            // extra={<Button type="link">{pencil}</Button>}
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <p className="text-dark">
              {" "}
              Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer
              is no. If two equally difficult paths, choose the one more painful
              in the short term (pain avoidance is creating an illusion of
              equality).{" "}
            </p>
            <hr className="my-25" />
            <Descriptions title="Oliver Liam">
              <Descriptions.Item label="Full Name" span={3}>
                Sarah Emily Jacob
              </Descriptions.Item>
              <Descriptions.Item label="Mobile" span={3}>
                (44) 123 1234 123
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                sarahjacob@mail.com
              </Descriptions.Item>
              <Descriptions.Item label="Location" span={3}>
                USA
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

      </Row>
      <Row gutter={[24, 0]}>
        <Col span={24} md={12} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Users</h6>}
            className="header-solid h-full"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <List
              itemLayout="horizontal"
              dataSource={data}
              split={false}
              className="conversations-list"
              renderItem={(item) => (
                <List.Item actions={[<Button type="link">REPLY</Button>]}>
                  <List.Item.Meta
                    avatar={
                      <Avatar shape="square" size={48} src={item.avatar} />
                    }
                    title={item.title}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col span={24} md={12} className="mb-24">
          <Card
              bordered={false}
              title={<h6 className="font-semibold m-0">Lexicon Admin</h6>}
              className="header-solid h-full"
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <List
                itemLayout="horizontal"
                dataSource={data}
                split={false}
                className="conversations-list"
                renderItem={(item) => (
                    <List.Item actions={[<Button type="link">REPLY</Button>]}>
                      <List.Item.Meta
                          avatar={
                            <Avatar shape="square" size={48} src={item.avatar} />
                          }
                          title={item.title}
                          description={item.description}
                      />
                    </List.Item>
                )}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Profile;
