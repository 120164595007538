import axiosApiInstance from "./interceptor";

export default class SuperAdminServices {
    static async getCompanyAdmin(page, limit = 10) {
        return  await axiosApiInstance.get(`SuperAdmin/GetCompanies?page=${page}&limit=${limit}`);
    }
    static async getCompanyIdAdmin(id) {
        return  await axiosApiInstance.get(`SuperAdmin/GetCompany?companyId=${id}`);
    }
    static async getCompanyUser(id, page, limit = 5) {
        return  await axiosApiInstance.get(`SuperAdmin/GetCompanyUsers?companyId=${id}&page=${page}&limit=${limit}`);
    }
    static async getCompanyLexiconAdmin(id, page, limit = 5) {
        return  await axiosApiInstance.get(`SuperAdmin/GetCompanySubAccounts?companyId=${id}&page=${page}&limit=${limit}`);
    }
}